exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-alumni-index-js": () => import("./../../../src/pages/alumni/index.js" /* webpackChunkName: "component---src-pages-alumni-index-js" */),
  "component---src-pages-coaches-index-js": () => import("./../../../src/pages/coaches/index.js" /* webpackChunkName: "component---src-pages-coaches-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-golfers-index-js": () => import("./../../../src/pages/golfers/index.js" /* webpackChunkName: "component---src-pages-golfers-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-licenses-index-js": () => import("./../../../src/pages/licenses/index.js" /* webpackChunkName: "component---src-pages-licenses-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-youth-wrestling-index-js": () => import("./../../../src/pages/youth-wrestling/index.js" /* webpackChunkName: "component---src-pages-youth-wrestling-index-js" */)
}

